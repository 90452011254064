import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const displayDate = (date) =>
  date.toLocaleDateString(`en-US`, {
    year: `numeric`,
    month: `short`,
    day: `2-digit`,
    timeZone: `UTC`, // UTC+00:00 set in Contentful to avoid time zone shift
  })

function FormattedDate(date) {
  const dateString = displayDate(new Date(date))
  const splitDate = dateString.split(' ')

  return (
    <>
      <span className="media-month">{splitDate[0]}</span>
      {` `}
      <span className="media-day">{splitDate[1].substring(0, 2)}</span>
      {` `}
      <span className="media-year">{splitDate[2]}</span>
    </>
  )
}

class MediaPage extends React.Component {
  render() {
    const media = this.props.data.contentfulMediaPage
    let sectionCount = 0
    return (
      <Layout location={this.props.location}>
        <Helmet title={media.title} />
        <section className="top">
          <h1 className="hero-title">{media.title}</h1>
        </section>
        <section>
          <header>
            <p className="section-number">0{(sectionCount += 1)}</p>
            <h2 id="selected-media">Selected Media</h2>
          </header>
          <div
            className="summary mt section-description"
            dangerouslySetInnerHTML={{
              __html: media.summary.childMarkdownRemark.html,
            }}
          />
          {media.items.length > 0 ? (
            <ul className="media-list section-description">
              {media.items.map((item) => {
                return (
                  <li key={item.id}>
                    {item.title && (
                      <h3>
                        {item.url ? (
                          <a href={item.url}>{item.title}</a>
                        ) : (
                          <span>{item.title}</span>
                        )}
                      </h3>
                    )}
                    <div className="media-meta">
                      <span>{item.date ? FormattedDate(item.date) : ``}</span>
                      {` `}
                      {item.date && item.outlet && <span>&middot;&nbsp;</span>}
                      {item.outlet && <span>{item.outlet}</span>}
                    </div>
                    {item.description && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.description.childMarkdownRemark.html,
                        }}
                      />
                    )}
                    {item.url && (
                      <a className="button mt-half mobile-full" href={item.url}>
                        {item.callToAction ? (
                          <span>{item.callToAction}</span>
                        ) : (
                          <span>Read</span>
                        )}
                      </a>
                    )}
                  </li>
                )
              })}
            </ul>
          ) : (
            <p>No showcased media right now.</p>
          )}
        </section>
      </Layout>
    )
  }
}

export default MediaPage

export const pageQuery = graphql`
  query MediaPageQuery {
    contentfulMediaPage(id: { eq: "1ec6d431-6256-54b6-9824-14cb11fb33af" }) {
      items {
        callToAction
        date
        description {
          childMarkdownRemark {
            html
          }
        }
        id
        outlet
        title
        url
      }
      summary {
        childMarkdownRemark {
          html
        }
      }
      title
    }
  }
`
